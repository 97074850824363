import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import {useSearchParams } from 'react-router-dom';
import './Screenhls.css';
import logo from '../../images/new-logo.png';
import ReactHlsPlayer from 'react-hls-player';

export const Screenhls = () => {
 console.log("In Screen HLS page");

 const [queryParams] = useSearchParams();
 const screenId = queryParams.get('id');
 const [screenData, setscreenData] = useState({});
 const [passCode, setPassCode] = useState('');
 const [passCodeVerified, setPassCodeVerified] = useState(0);
 const [errormsg, setErrorMessage] = useState('');
 const currentPlayingOrder = useRef('');
 let apiInterval = useRef(null);

 const passCodeSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if(!passCode){
      setErrorMessage("Passcode is required.");
      return;
    }
  }

  useEffect(() => {
    if(screenId){
      setPassCodeVerified(1);
      checkForUpdates(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkForUpdates(pass){
    if(!pass){return;}
    clearInterval(apiInterval.current);
    apiInterval.current = setInterval(() => {
      fetchApiUpdates();
    }, 3000);
  }

  function fetchApiUpdates(){
    let screenApi = process.env.REACT_APP_API_ENDPOINT+"screen/screen-details/" +screenId;
    const headers = {
      'Content-Type': 'application/json'
    }
    axios.get(screenApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.data){
          if(res.data.data.currently_playing){
            if(Object.keys(res.data.data.currently_playing).length){
              if(res.data.data.currently_playing.order_id !== currentPlayingOrder.current){
                setscreenData(res.data.data.currently_playing);
                currentPlayingOrder.current = res.data.data.currently_playing.order_id;
              }
            }else{
              setscreenData({});
            }
          }else{
            setscreenData({});
            currentPlayingOrder.current = '';
          }
        }
      }else{
        setscreenData({});
        currentPlayingOrder.current = '';
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function createHTMLContent(){
    return {__html: customizedText}
  }

  let customizedText = '';
  let mediaContentObj = {};
  if(Object.keys(screenData).length){
    const mediaContent = screenData.styles;
    mediaContentObj = JSON.parse(mediaContent);
    if(Object.keys(mediaContentObj).length){
      customizedText = mediaContentObj.text;
    }
  }
  
  return (
    <>
      { passCodeVerified ?
        Object.keys(screenData).length ?
        <div className="Admin-page">
          <div className="mediaCont" style={{ maxHeight:+window.innerHeight+'px' }}>
          {
            screenData.request_type === 102 ? 
              (screenData.hls_url)?
              <ReactHlsPlayer src={screenData.hls_url}  width="100%" height="100%" style={{ maxHeight:+window.innerHeight+'px' }} autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} />
              :
              <video src={screenData.url} width="100%" height="100%" style={{ maxHeight:+window.innerHeight+'px' }} playsInline autoPlay muted loop></video> 
            :
            <div className="bannerImg" style={{backgroundImage: "url(" + screenData.url + ")"}}></div>
          }
          </div>
          {
            (customizedText !== '') &&
            <p className="captionCont" style={mediaContentObj.parentStyles}>
              <span className="captionText" style={{backgroundColor:mediaContentObj.childStyles.backgroundColor, textShadow:((mediaContentObj.childStyles.textShadow)?mediaContentObj.childStyles.textShadow:"")}} dangerouslySetInnerHTML={createHTMLContent()}></span>
            </p>
          }
        </div>
        :
        <div className="Admin-page">
          <img src={logo} alt="logo" className="marginNTop" />
        </div>
        :
        <div className="Admin-page">
          <form id="passcodeForm" autoComplete="off" onSubmit={passCodeSubmit}>
            <label>Enter Passcode</label><br/>
            <input type="password" value={passCode} autoComplete="off" onChange={(e) => setPassCode(e.target.value)} placeholder="" /><br/><br/>
            {errormsg && 
              <div className='error'>{errormsg}</div>
            }
            <br/>
            <button type="submit" className="Admin-button">Submit</button><br/>
          </form>
        </div>
      }
    </>
  );
}