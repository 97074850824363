import { useState, useEffect } from 'react';
import {useNavigate, Link, createSearchParams, useSearchParams} from 'react-router-dom';
import logo from '../../images/logo.png';
import photoIcon from '../../images/photo-icon.png';
import videoIcon from '../../images/video-icon.png';
import uploadIcon from '../../images/upload-icon.png';
import cameraIcon from '../../images/camera-icon.png';
import WebcamImage from '../../components/WebcamImage';
import WebcamVideo from '../../components/WebcamVideo';
import {UploadingProgressBar} from '../../components/UploadingProgressBar';
import axios from 'axios';
import '../usermedia/UserMedia.css'
import { Signin } from '../signin/Signin';

export const UserMedia = () => {
  console.log("In User Media page");

  const navigate = useNavigate();
  const usertoken = localStorage.getItem('appusertoken');
  const [pagestate, setPageState] = useState(1);  // 1-all media options
  const [mediaType, setMediaType] = useState(''); // 2-upload photo, 3-upload video, 4-take photo, 5-take video
  const [file, setFile] = useState();
  const [uploadBtnState, setUploadBtnState] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [videoDurationError, setVideoDurationError] = useState(false);
  const combinedFlag = 1; //  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)?1:0;
  const isAndroid = navigator.userAgent.match(/Android/i)?',audio/*':'';

  const [queryParams] = useSearchParams();
  const bid = queryParams.get('bid')?queryParams.get('bid'):'';
  const type = queryParams.get('t')?parseInt(queryParams.get('t')):'';

  const [customPhotoPrice, setCustomPhotoPrice] = useState('');
  const [customVideoPrice, setCustomVideoPrice] = useState('');
  const [freeOrder,setFreeOrder] = useState('');

  useEffect(() => {
    if(type !== ''){
      setPageState(type);
      selectMediaType(type);
    }
  }, [type]);

  useEffect(() => {
    const tokenAPI = localStorage.getItem('appusertoken');
    const clubID = localStorage.getItem('appcid');
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    let tbID = localStorage.getItem('appusertblid');
    let firstOrderApi = process.env.REACT_APP_API_ENDPOINT+"client-app/is-first-order/"+tbID+"/"+clubID;
    axios.get(firstOrderApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.first_order && res.data.first_order === true){
          setFreeOrder('FREE');
        }else{
          setFreeOrder('$');
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });

    let customMediaPriceApi = process.env.REACT_APP_API_ENDPOINT+"client-app/custom-media-prices/"+clubID;
    axios.get(customMediaPriceApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data.custom_media_prices && res.data.custom_media_prices.length){
          res.data.custom_media_prices.forEach(mediaPriceItem => {
            if(mediaPriceItem.custom_media === 101){
              setCustomPhotoPrice('$'+mediaPriceItem.price);
            }else if(mediaPriceItem.custom_media === 102){
              setCustomVideoPrice('$'+mediaPriceItem.price);
            }
          });
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  function handleFileChange(e) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setUploadBtnState(1);
      setVideoDurationError(false);
  }

  const selectMediaType = (type) => {
    setMediaType(type);
    setFile('');
    setUploadBtnState(0);
  }

  const continueBtn = (e) => {
    if(mediaType !== ''){
      setPageState(mediaType);
    }
  }

  const uploadBtn = (e) => {
    let uploadApi = process.env.REACT_APP_API_ENDPOINT+"client-app/upload";
    let formData = new FormData();
    let imagefile = document.querySelector('#pickFile');
    let mtype = ((mediaType === 2)?'image':'video');
    setUploadBtnState(0);
    formData.append("file", imagefile.files[0]);
    formData.append("type", mtype);
    axios.post(uploadApi, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Token': usertoken
        },
        onUploadProgress: (progressEvent) => {
          setProgressBarValue(parseInt(progressEvent.progress * 100));
        }
    })
    .then(res => {
      setUploadBtnState(1);
      if(res.status === 200){
        if(bid !== ''){
          navigate({
            pathname: '/customize',
            search: createSearchParams({
              mo: res.data.id,
              c: "1",
              bid: bid,
              mode: "edit"
            }).toString()
          });
          return;          
        }
        if(res.data.id){
          navigate({
            pathname: '/preview-custom-selection',
            search: createSearchParams({
              co: res.data.id
            }).toString()
          });
        }
      }
    })
    .catch((error) => {
      setUploadBtnState(1);
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
  }

  if(pagestate === 3 && file && !videoDurationError){
    setTimeout(function(){
      var videoTag = document.getElementsByTagName('video');
      var selectedVideoDuration = (parseInt(videoTag[0].duration));
      if(selectedVideoDuration < 1 || selectedVideoDuration > 60){ // one minute limit to be consistent with take video
        setVideoDurationError(true);
        setFile('');
      }
    },500);
  }

  return (
    <>
    {usertoken ? 
      <div className="App-page">
          <section className="topSection">
            <img src={logo} className="App-logo-medium" alt="logo" /><br/>
            { 
              (bid !== '')?
              <span className="backLink"><Link to={"/customize?mo=&c=1&bid="+bid+"&mode=edit"}></Link></span>
              // : (pagestate === 2 || pagestate === 3) ?
              // <span className="backLink" onClick={(e) => setPageState(1)}></span>
              :
              <span className="backLink"><Link to="/digital-sign"></Link></span>
            }
          </section>
          <section className="bottomSection"><br/>
          { (pagestate === 2 || pagestate === 3) ?
            <div className="uploadMediaCont">
              <div>
                <label className="filePicker" htmlFor="pickFile">
                <div className="mediaCont" style={{ backgroundImage:'url('+((pagestate === 2) ? photoIcon : uploadIcon)+')' }}></div>
                <strong>{combinedFlag === 0 ? "" : "Tap to Take / "}Upload a {(pagestate === 2) ? "Photo" : "Video"}</strong></label>
                <span className='pricetag'>{(pagestate === 2) ? (freeOrder !== '$'?freeOrder:customPhotoPrice) : (freeOrder !== '$'?freeOrder:customVideoPrice)}</span><br/>
                <input id="pickFile" type="file" accept={(pagestate === 2) ? "image/*"+isAndroid : "video/*"+isAndroid} onChange={handleFileChange} style={{display:'none'}}/>
                {(pagestate === 2 && file) ?
                  <img src={file} alt="" width="85" style={{display:'inline-block'}}/>
                : (pagestate === 3 && file) ?
                  <video src={file} disabled width="85" style={{display:'inline-block'}}></video>
                :
                  <span></span>
                }
                {
                  (videoDurationError && pagestate === 3) &&
                  <div><small className='videoError'>Error: please upload a video no longer than 60 seconds.</small></div>
                }
              </div>
              <small className='btmNote'>{(pagestate === 2) ? "Vertical photos work best." : "Max limit of 60 seconds. Vertical videos work best."}</small>
            </div>
            : (pagestate === 4 || pagestate === 5) ?
            <div className='captureCont'>
              <button className="captureCloseBtn" onClick={(e) => setPageState(1)}>X</button>
              {(pagestate === 4)?
                <WebcamImage /> 
              :
              <WebcamVideo />
              }
            </div>
            :
            <div className="mediaTypeCont">
              <div className={`mediaType ${2 === mediaType ? 'active':''} ${combinedFlag === 0 ? '':'halfWidth'}`} onClick={(e) => selectMediaType(2)}>
                <div className="mediaCont" style={{ backgroundImage:'url('+photoIcon+')' }}></div>
                <strong>{combinedFlag === 0 ? "Upload A Photo":"Take / Upload A Photo"}</strong>
                <small>{freeOrder !== '$'?freeOrder:customPhotoPrice}</small>
              </div>
              <div className={`mediaType ${3 === mediaType ? 'active':''} ${combinedFlag === 0 ? '':'halfWidth'}`} onClick={(e) => selectMediaType(3)}>
                <div className="mediaCont" style={{ backgroundImage:'url('+uploadIcon+')' }}></div>
                <strong>{combinedFlag === 0 ? "Upload A Video":"Take / Upload A Video"}</strong>
                <small>{freeOrder !== '$'?freeOrder:customVideoPrice}</small>
              </div>
              {
                combinedFlag === 0 &&
                <div className={`mediaType ${4 === mediaType ? 'active':''}`} onClick={(e) => selectMediaType(4)}>
                  <div className="mediaCont" style={{ backgroundImage:'url('+cameraIcon+')' }}></div>
                  <strong>Take A Photo</strong>
                  <small>{freeOrder !== '$'?freeOrder:customPhotoPrice}</small>
                </div>
              }{ 
                combinedFlag === 0 &&
                <div className={`mediaType ${5 === mediaType ? 'active':''}`} onClick={(e) => selectMediaType(5)}>
                  <div className="mediaCont" style={{ backgroundImage:'url('+videoIcon+')' }}></div>
                  <strong>Take A Video</strong>
                  <small>{freeOrder !== '$'?freeOrder:customVideoPrice}</small>
                </div> 
              }
                           
            </div>
          }
            <br/><br/>
            {
              (pagestate === 2 || pagestate === 3)?
              <>
                { (file && !uploadBtnState && !videoDurationError) &&
                  <UploadingProgressBar progressBarValue={progressBarValue} ></UploadingProgressBar>
                }
                <button id="userUploadMediaBtn" className="App-button fadeBtn" disabled={(file && uploadBtnState)?'':'disabled'} onClick={(e) => uploadBtn(e)}>Upload</button>
              </>
              : (pagestate === 4 || pagestate === 5) ?
              <span></span>
              :
              <button id="userMediaContinueBtn" className="App-button" disabled={(mediaType)?'':'disabled'} onClick={(e) => continueBtn(e)}>Continue</button>
            }
          </section>
      </div>
      :
      <Signin/>
    }
    </>
  );
}