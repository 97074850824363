import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import {useNavigate, useSearchParams, createSearchParams, Link} from 'react-router-dom';
import './Customize.css';
import { HexColorPicker } from "react-colorful";
import { Signin } from '../signin/Signin';
import Draggable from 'react-draggable';

const DEFAULT_FILTER_OPTIONS = [
  {
    name: 'Font Family',
    property:'fontFamily',
    value:'Open Sans',
    class: 'btn1'
  },
  {
    name: 'Font Size',
    property:'fontSize',
    value:'5vh',
    class: 'btn6'
  },
  {
    name: 'Text Color',
    property:'color',
    value:'#FFFFFF',
    class: 'btn4'
  },
  {
    name: 'Background Color',
    property:'backgroundColor',
    value:'unset',
    class: 'btn5'
  },
  {
    name: 'Vertical Align',
    property:'top',
    value:'49%',
    class: 'btn2'
  },
  {
    name: 'Text Alignment',
    property:'textAlign',
    value:'center',
    class: 'btn3'
  }
]

const DEFAULT_FONT_OPTIONS = [
  {
    value:'Open Sans',
    class: 'fontBtn1'
  },
  {
    value:'Young Serif',
    class: 'fontBtn2'
  },
  {
    value:'Dancing Script',
    class: 'fontBtn3'
  },
  {
    value:'Caveat',
    class: 'fontBtn4'
  },
  {
    value:'Archivo Black',
    class: 'fontBtn5'
  },
  {
    value:'Bebas Neue',
    class: 'fontBtn6'
  }
]

const DEFAULT_VERTICAL_ALIGN = [
  {
    property:'top',
    value:'5%',
    class: 'vaBtn1'
  },
  {
    property:'top',
    value:'50%',
    class: 'vaBtn2'
  },
  {
    property:'bottom',
    value:'5%',
    class: 'vaBtn3'
  },
  {
    property:'top',
    value:'49%',
    class: 'vaBtnCustom'
  }
]

const DEFAULT_TEXT_ALIGN = [
  {
    value:'left',
    class: 'taBtn1'
  },
  {
    value:'center',
    class: 'taBtn2'
  },
  {
    value:'right',
    class: 'taBtn3'
  }
]

const DEFAULT_TEXT_COLORS = [
  {
    value:'#ff0000',
    class: 'colorred'
  },
  {
    value:'#b1ddf3',
    class: 'colorblue'
  },
  {
    value:'#165E0B',
    class: 'colorgreen'
  },
  {
    value:'#fcba03',
    class: 'colororange'
  },
  {
    value:'#FF2EAA',
    class: 'colorpink'
  }
]

const DEFAULT_TEXT_BACKGROUND = [
  {
    value:'transparent',
    class: 'bgcolortransparent',
    textEffect: '0px 0px 0px transparent'
  },
  {
    value:'rgba(0,0,0,0.5)',
    class: 'bgcolorblack',
    textEffect: '0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2, 0px 0px 1px #f2f2f2'
  },
  {
    value:'rgba(255,255,255,0.5)',
    class: 'bgcolorwhite',
    textEffect: '0px 0px 1px #002133, 0px 0px 1px #002133, 0px 0px 1px #002133, 0px 0px 1px #002133'
  }
]

export const Customize = () => {
 console.log("In Customize page");
 
 const filterOptions = DEFAULT_FILTER_OPTIONS;
 const [selectedOptionIndex, setSelectedOptionIndex] = useState();

 const usertoken = localStorage.getItem('appusertoken');
 const navigate = useNavigate();
 const [queryParams] = useSearchParams();
 const mo = queryParams.get('mo');
 const custom = queryParams.get('c');
 const mode = queryParams.get('mode');
 const editableBid = (queryParams.get('bid')?queryParams.get('bid'):null);
 const [mediaData, setMediaData] = useState([]);
 const [pageState, setPageState] = useState(1);
 const [refreshState, setRefreshState] = useState(0);
 const fontOptions = DEFAULT_FONT_OPTIONS;
 let vaOptions = DEFAULT_VERTICAL_ALIGN;
 const taOptions = DEFAULT_TEXT_ALIGN;
 const colorOptions = DEFAULT_TEXT_COLORS;
 const backgroundcolorOptions = DEFAULT_TEXT_BACKGROUND;
 const [selectedFontOptionIndex, setSelectedFontOptionIndex] = useState(0);
 const [selectedVAOptionIndex, setSelectedVAOptionIndex] = useState(3);
 const [selectedTAOptionIndex, setSelectedTAOptionIndex] = useState(1);
 const [selectedColorOptionIndex, setSelectedColorOptionIndex] = useState();
 const [selectedBackgroundColorOptionIndex, setSelectedBackgroundColorOptionIndex] = useState(0);

 const [textContent, setTextContent] = useState('');
 const textareaHeight = useRef('auto');
//  const textareaTPosition = (vaOptions[selectedVAOptionIndex].property === 'top')?"0px":"unset";
//  const textareaBPosition = (vaOptions[selectedVAOptionIndex].property === 'bottom')?"0px":"unset";
 const maxContHeightPX = (Math.round(window.innerHeight / (100 / 80)));
 const marginTopNeg = (vaOptions[selectedVAOptionIndex].value === '50%')?((textareaHeight.current <= maxContHeightPX)?-textareaHeight.current/2 + 'px':-maxContHeightPX/2 + 'px'):'0px';
 const backgroundColorFilterValue = backgroundcolorOptions[selectedBackgroundColorOptionIndex].value;
 const backgroundColorFilterTextEffect = backgroundcolorOptions[selectedBackgroundColorOptionIndex].textEffect;
 const [customcolor, setCustomColor] = useState("#ffffff");
 const textareaRef = useRef(null);
 const dragHeightBounds = parseInt(window.innerHeight/2 - window.innerHeight*0.05);
 const dragWidthBounds = parseInt(window.innerWidth/2 - window.innerWidth*0.15);
 const [customColorView, setCustomColorView] = useState(false);
 const clickTimeRef = useRef(0);
 const textareaLeft = useRef('10%');
 const [fontsize, setFontSize] = useState(5);

 const [dragging, setDragging] = useState(false);
 const trashRef = useRef(null);
 const [isIntersectTrash,setIntersectTrash] = useState(false);
 const [counter, setCounter] = useState(1);

 useEffect(() => {
  if(mode === 'edit' && editableBid !== null){
    const tokenAPI = localStorage.getItem('appusertoken');
    let orderDetailsApi = process.env.REACT_APP_API_ENDPOINT+"client-app/get-rejected-order-detail/"+editableBid;
    const headers = {
      'Content-Type': 'application/json',
      'Token': tokenAPI
    }
    axios.get(orderDetailsApi, {
      headers: headers
    })
    .then(res => {
      if(res.status === 200){
        if(res.data){
          let apiBookingData = res.data.rejected_order_details.BookingDetails;
          let mediaDataObj = {
            "currency": "$",
            "hls_url": apiBookingData.HLSUrl,
            "id": null,
            "mime": (apiBookingData.RequestType === 102?"video/mp4":"image/jpeg"),
            "price": apiBookingData.RequestPayment,
            "url": apiBookingData.RequestMedia,
            "type": (apiBookingData.RequestType === 102?3:2)
          }
          if(!mo){
            setMediaData(mediaDataObj);
          }
          if(apiBookingData.RequestAttribs){
            let textStyles = JSON.parse(apiBookingData.RequestAttribs);
            if(Object.keys(textStyles).length){
              setTextContent(textStyles.text);
              filterOptions.forEach(option => {
                if(option.property === 'color'){
                    option.value = textStyles.parentStyles.color;
                  }else if(option.property === 'fontFamily'){
                      option.value = textStyles.parentStyles.fontFamily;
                  }else if(option.property === 'textAlign'){
                      option.value = textStyles.parentStyles.textAlign;
                  }else if(option.property === 'fontSize'){
                    option.value = textStyles.parentStyles.fontSize;
                  }else if(option.name === 'Vertical Align'){
                    if(typeof(textStyles.parentStyles.top) !== 'undefined' || typeof(textStyles.parentStyles.bottom) !== 'undefined'){
                      setSelectedVAOptionIndex(3);
                      DEFAULT_VERTICAL_ALIGN.forEach((vaoption,i) => {
                        if(typeof(textStyles.parentStyles.top) !== 'undefined' && vaoption.value === textStyles.parentStyles.top){
                          setSelectedVAOptionIndex(i);
                          return false;
                        }else if(typeof(textStyles.parentStyles.bottom) !== 'undefined' && vaoption.value === textStyles.parentStyles.bottom){
                          setSelectedVAOptionIndex(i);
                          return false;
                        }else{
                          vaOptions[3].value = textStyles.parentStyles.top;
                        }
                        let wh50 = window.innerHeight*0.46;
                        let ht = window.innerHeight*parseInt(textStyles.parentStyles.top.replace('%',''))/100;
                        let wt = window.innerWidth*(parseInt(textStyles.parentStyles.left.replace('%',''))-10)/100;
                        document.getElementById('captionContID').style.transform = "translate("+(wt)+"px,"+(ht-wh50)+"px)"
                      });
                    }
                  }
              });
              if(textStyles.childStyles.backgroundColor){
                DEFAULT_TEXT_BACKGROUND.forEach((bgoption,i) => {
                  if(bgoption.value === textStyles.childStyles.backgroundColor){
                    setSelectedBackgroundColorOptionIndex(i);
                    return false;
                  }
                });
              }
              textareaLeft.current = textStyles.parentStyles.left;
            }
          }
        }
      }
    })
    .catch((error) => {
      if(error.response.status === 401){
        localStorage.clear();
        navigate({ pathname: '/' });
      }
    });
  }

  if(!mo){return;}
  const tokenAPI = localStorage.getItem('appusertoken');
  let moPreviewApi = process.env.REACT_APP_API_ENDPOINT+"client-app/"+((custom === "1")?"get-custom-media/":"get-single-digital-object-club/")+mo;
  const headers = {
    'Content-Type': 'application/json',
    'Token': tokenAPI
  }
  axios.get(moPreviewApi, {
    headers: headers
  })
  .then(res => {
    if(res.status === 200){
      if(res.data){
        if(custom === "1" && res.data.custom_media_details){
          setMediaData(res.data.custom_media_details);
        }else if(res.data.digital_sign){
          setMediaData(res.data.digital_sign);
        }
      }
    }
  })
  .catch((error) => {
    if(error.response.status === 401){
      localStorage.clear();
      navigate({ pathname: '/' });
    }
  });
  resetFilters(); // when someone comes again on this page for other orders
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function resetFilters(){
    filterOptions.forEach(option => {
      if(option.property === 'color'){
          option.value = '#FFFFFF';
        }else if(option.property === 'fontFamily'){
            option.value = 'Open Sans';
        }else if(option.property === 'textAlign'){
            option.value = 'center';
        }else if(option.property === 'fontSize'){
          option.value = '5vh';
        }
    });
  }
 
 function getFilterStyles(){
    const filters = {};
    filterOptions.map(option => {
      return(filters[`${option.property}`] = option.value)
    })
    filters['marginTop'] = marginTopNeg;
    filters['left'] = textareaLeft.current;
    return filters;
 }

 function getFilterPropsAPI(){
  const filterProps = {};
  if(textContent.length){
    let textContentHTML = textContent.replace(/\r\n|\r|\n/g,"<br />");  // Convert new lines to html tags
    filterProps.text = textContentHTML;
    filterProps.parentStyles = getFilterStyles();
    filterProps.childStyles = {backgroundColor: backgroundColorFilterValue, textShadow: backgroundColorFilterTextEffect}

    if(filterProps.parentStyles.top){  // recalculate top before submit to avoid differences
      let captionTop = (parseInt((document.getElementById('captionContID').getBoundingClientRect().y + window.scrollY)*100/window.innerHeight));  // add scroll Y too to avoid differences
      if(captionTop > 98){captionTop = 98;}
      filterProps.parentStyles.top = captionTop+'%';
    }
  }
  return filterProps;
 }

 const updateFont = (i) => {
    setSelectedFontOptionIndex(i);
    let selectedFontValue = '';
    fontOptions.forEach((font,j) => {
      if(i === j){
        selectedFontValue = font.value;
      }
    });
    if(selectedFontValue !== ''){
        filterOptions.forEach(option => {
          if(option.name === 'Font Family'){
              option.value = selectedFontValue;
          }
        })
    }
    updateTextareaHeightonFontChange();
 }

 const updateVAlign = (i) => {
  let selectedVAProp = '';
  let selectedVAValue = '';
  vaOptions.forEach((valign,j) => {
    if(i === j){
      selectedVAProp = valign.property;
      selectedVAValue = valign.value;
    }
  });
  if(selectedVAValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Vertical Align'){
            option.property = selectedVAProp;
            option.value = selectedVAValue;
        }
      })
  }
  setSelectedVAOptionIndex(i);
}

const updateTAlign = (i) => {
  setSelectedTAOptionIndex(i);
  let selectedTAValue = '';
  taOptions.forEach((talign,j) => {
    if(i === j){
      selectedTAValue = talign.value;
    }
  });
  if(selectedTAValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Text Alignment'){
            option.value = selectedTAValue;
        }
      })
  }
}

const updateTextColor = (i,c) => {
  setSelectedColorOptionIndex(i);
  let selectedColorValue = '';
  if(i === 6){
    selectedColorValue = c;
    setCustomColor(c);
  }else{
    colorOptions.forEach((color,j) => {
      if(i === j){
        selectedColorValue = color.value;
      }
    });
  }

  if(selectedColorValue !== ''){
      filterOptions.forEach(option => {
        if(option.name === 'Text Color'){
            option.value = selectedColorValue;
        }
      })
  }
}

 const updateText = (e) => {
    setTextContent(e.target.value); 
    // Update textarea height based on content height
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight +'px';
    textareaHeight.current = e.target.scrollHeight;
 }

 const showCustomColorView = (i) => {
    setCustomColorView(!customColorView);
    setSelectedColorOptionIndex(6);
}

 const nextBtnClick = () => {
    setPageState(2);
 }

 const dragEvent = (e) => {
    let draggedLeft = (parseInt(document.getElementById('captionContID').getBoundingClientRect().x*100/window.innerWidth));
    let draggedTop = (parseInt(document.getElementById('captionContID').getBoundingClientRect().y*100/window.innerHeight));

    if(draggedTop > 97){draggedTop = 97;}
    console.log("draggedTop >> "+draggedTop);
    console.log("draggedLeft >> "+draggedLeft);

    setDragging(true);
    vaOptions[3].value = draggedTop+'%';
    textareaLeft.current = draggedLeft+'%';

    const trashElement = trashRef.current;
    const trashRect = trashElement && trashElement.getBoundingClientRect();
    // Get the dragged element's bounding rectangle
    const draggedElementRect = document.getElementById('captionContID').getBoundingClientRect(); //e.target.getBoundingClientRect();
    // Check for intersection between the dragged element and the trash icon
    if(!trashRect || !draggedElementRect) {
      return;
    }
    if(!e.targetTouches) {
      var intersectsTrash =
      e.clientX < trashRect.right &&
      e.clientX > trashRect.left &&
      e.clientY < trashRect.bottom &&
      e.clientY > trashRect.top;
    } else {
      var intersectsTrash =
      e.targetTouches[0].clientX < trashRect.right &&
      e.targetTouches[0].clientX > trashRect.left &&
      e.targetTouches[0].clientY < trashRect.bottom &&
      e.targetTouches[0].clientY > trashRect.top;
    }
    if(intersectsTrash) {
      setIntersectTrash(true);
    } else {
      setIntersectTrash(false);
    }

    updateVAlign(3);
 }

 const handleStop = (e) => {
  if(dragging){
    setDragging(false);
    if (isIntersectTrash) {
      // Intersection occurred, perform your desired action here
        setTextContent('');
        // document.getElementById("captionContID").style.transform = "translate(0px,0px)";
        setSelectedOptionIndex('')
        setCounter(counter + 1); // reset draggable key
      }
      setIntersectTrash(false);
    } 
};
 const dragStartEvent = (e) => {
  setDragging(false);
 }

 const handleBottomBtnsClick = (event) => {
    if(event === 'edit'){
      setPageState(1);
    }else if(event === 'confirm'){
      let confirmApi = process.env.REACT_APP_API_ENDPOINT+"client-app/save-customization";
      const headers = {
        'Content-Type': 'application/json',
        'Token': usertoken
      }
      const data = {
        styles: getFilterPropsAPI(),
        tableid: localStorage.getItem('appusertblid'),
        requestmedia: mediaData.url,
        currency: mediaData.currency,
        price: mediaData.price,
        sign_id: mediaData.id,
        custom: parseInt(custom),
        requestmediatype: (((mediaData.mime).indexOf('video') > -1)?102:101),
        booking_id: editableBid
      };
      
      axios.post(confirmApi, data, {
        headers: headers
      })
      .then(res => {
        if(res.status === 200){
          if(mode === 'edit' && editableBid === res.data.booking_id){
            navigate({ pathname: '/update-success' });
          }else if(res.data && res.data.booking_id){
            navigate({
              pathname: '/review-order',
              search: createSearchParams({
                b_id: res.data.booking_id,
                mo: mo,
                c: custom
              }).toString()
            });
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 401){
          localStorage.clear();
          navigate({ pathname: '/' });
        }
      });

    }
 }

 function createHTMLContent(){
  let textContentHTML = textContent.replace(/\r\n|\r|\n/g,"<br />");  // Convert new lines to html tags
  return {__html: textContentHTML}
 }

  useEffect(() => {
    if(textareaRef.current && selectedOptionIndex === 0){
      textareaRef.current.focus();
    }
  }, [selectedOptionIndex]);

  const handleBodyClick = (e) => {
    var parentEl = document.querySelector('#colorpicker');
    if(parentEl && !parentEl.contains(e.target) && customColorView){
      setCustomColorView(false);
    }
  }

  const captionClicks = (e) => {
    if(!clickTimeRef.current){
      clickTimeRef.current = e.timeStamp;
    }else{
      if(e.timeStamp - clickTimeRef.current > 0 && e.timeStamp - clickTimeRef.current < 300){ // double click/touch
          setSelectedOptionIndex(0);
          textareaRef.current.focus();
          clickTimeRef.current = 0;
      }else{
        clickTimeRef.current = e.timeStamp;
      }
    }
  }

  const textareaClicks = (e) => {
    if(!clickTimeRef.current){
      clickTimeRef.current = e.timeStamp;
    }else{
      if(e.timeStamp - clickTimeRef.current > 0 && e.timeStamp - clickTimeRef.current < 300){ // double click/touch
          textareaRef.current.focus();
          clickTimeRef.current = 0;
      }else{
        clickTimeRef.current = e.timeStamp;
      }
    }
  }

  const updateFontSize = (e) => {
    if(e.target.value !== ''){
        filterOptions.forEach(option => {
          if(option.name === 'Font Size'){
              option.value = e.target.value +'vh';
          }
        })
    }
    setFontSize(e.target.value);
    updateTextareaHeightonFontChange();
  }

  const updateTextareaHeightonFontChange = () => {
    var textareaEle = document.getElementsByTagName('textarea');
    var captionText = document.getElementsByClassName('captionText');
    var onElement = null;
    if(selectedOptionIndex === 0 && textareaEle.length){
      onElement = textareaEle[0];
    }else if(captionText.length){
      onElement = captionText[0];
    }
    setTimeout(function(){
      if(onElement && onElement.scrollHeight){
        textareaEle[0].style.height = onElement.scrollHeight +'px';
        textareaHeight.current = onElement.scrollHeight;
      }
      setRefreshState(!refreshState);
    },50);
  }

  return (
    <>
    {usertoken ? 
      <div className="App-page" onClick={(e) => handleBodyClick(e)}>
        {
          (mode === 'edit' && custom === '1') ?
            <span className="backLink linkZIndex"><Link to={"/user-media?bid="+editableBid+"&t="+mediaData.type}></Link></span>
          : (mode === 'edit' && custom === '0') ?
            <span className=""></span>
          :
            <span className="backLink linkZIndex"><Link to="/digital-sign"></Link></span>
        }
        <div className="mediaCont">
          {
          mediaData.url ?
            ((mediaData.mime).indexOf('video') > -1) ?
            <video src={mediaData.url} width="100%" height="100%" style={{ maxHeight:'100vh' }} playsInline autoPlay muted loop></video>
            : ((mediaData.mime).indexOf('image') > -1) ?
            <div className="bannerImg" style={{ backgroundImage:'url('+mediaData.url+')' }}></div>
            :
            <div></div>
           :
           <div></div>
          }
        </div>
        <div className="filterOverlay"></div>
        <Draggable key={counter} axis="both" disabled={(pageState == 2)?true:false} bounds={{top:-dragHeightBounds, bottom:dragHeightBounds + 50, left:-dragWidthBounds, right:dragWidthBounds}} onDrag={(e) => dragEvent(e)} onStart={(e) => dragStartEvent(e)} onStop={(e)=>handleStop(e)}>
        <p id="captionContID" className={`captionCont ${(selectedVAOptionIndex === 3)?'unsetPos':'unsetTransform'}`} style={getFilterStyles()}>
          <span className="captionText" onClick={(e) => captionClicks(e)} onTouchEnd={(e) => captionClicks(e)} style={{display: ((selectedOptionIndex !== 0 || pageState === 2) && textContent !== '')?'':'none', backgroundColor:backgroundColorFilterValue, textShadow: backgroundColorFilterTextEffect}} dangerouslySetInnerHTML={createHTMLContent()}></span>
          { pageState === 1 &&
          <textarea ref={textareaRef} id="textInput" rows="1" onClick={(e) => textareaClicks(e)} onTouchEnd={(e) => textareaClicks(e)} style={{display: (selectedOptionIndex === 0)?'block':'none', height:textareaHeight.current+'px', backgroundColor:backgroundColorFilterValue, textShadow: backgroundColorFilterTextEffect}} onChange={(e) => updateText(e)} value={textContent}></textarea>
          }
        </p>
        </Draggable>

        {
          (dragging) &&
          <button ref={trashRef} className={`trash ${(isIntersectTrash) ? 'zoomtrash' : ''}`}></button>
        }

        {/* Filter options on left side */}
        { pageState === 1 &&
        <div className="filterPanel">
          { filterOptions.map((option,index) => {
              return (
                <button key={index} className={`filterOption ${option.class} ${index === selectedOptionIndex ? 'active':''}`} onClick={() => (index === selectedOptionIndex) ? setSelectedOptionIndex('') : setSelectedOptionIndex(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Font Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 0)?'block':'none'}}>
          { fontOptions.map((option,index) => {
              return (
                <button key={index} className={`fontOption ${option.class} ${index === selectedFontOptionIndex ? 'active':''}`} onClick={() => updateFont(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Vertical Alignment Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 4)?'block':'none'}}>
          { vaOptions.map((option,index) => {
              return (
                <button key={index} className={`vaOption ${option.class} ${index === selectedVAOptionIndex ? 'active':''}`} onClick={() => updateVAlign(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Text Alignment Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 5)?'block':'none'}}>
          { taOptions.map((option,index) => {
              return (
                <button key={index} className={`taOption ${option.class} ${index === selectedTAOptionIndex ? 'active':''}`} onClick={() => updateTAlign(index)}></button>
              )
            })
          }
        </div>
        }
        {/* Text Color Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 2)?'block':'none'}}>
          { colorOptions.map((option,index) => {
              return (
                <button key={index} className={`colorOption ${option.class} ${index === selectedColorOptionIndex ? 'active':''}`} onClick={() => updateTextColor(index,'')}></button>
              )
            })
          }
          <button className={`colorOption colorcustom ${6 === selectedColorOptionIndex ? 'active':''}`} onClick={() => showCustomColorView(6)}></button>
          { (6 === selectedColorOptionIndex && customColorView) &&
            <HexColorPicker id="colorpicker" color={customcolor} onChange={(e) => updateTextColor(6,e)} />
          }
        </div>
        }
        {/* Background text Color Filter options on right side */}
        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 3)?'block':'none'}}>
          { backgroundcolorOptions.map((option,index) => {
              return (
                <button key={index} className={`bgcolorOption ${option.class} ${index === selectedBackgroundColorOptionIndex ? 'active':''}`} onClick={() => setSelectedBackgroundColorOptionIndex(index)}></button>
              )
            })
          }
        </div>
        }

        { pageState === 1 &&
        <div className="filterOptionsPanel" style={{display: (selectedOptionIndex === 1)?'block':'none'}}>
          <div className='fontSlider'>
            <input type="range" className="slider" name="fontSize" value={fontsize} min="3" step="1" onChange={(e) => updateFontSize(e)} max="15" orient="vertical" />
          </div>
        </div>
        }
        {
          pageState === 1 &&
          <button id="userCustomizeNextBtn" className="nextBtn" onClick={() => nextBtnClick()}>&nbsp;</button>
        }
        { pageState === 2 &&
        <div className="bottomBtns">
          <button id="userCustomizeEditBtn" className="App-button" onClick={() => handleBottomBtnsClick('edit')}>Edit </button>
          <button id="userCustomizeConfirmBtn" className="App-button" onClick={() => handleBottomBtnsClick('confirm')}>Confirm </button>
        </div>
        }
      </div>
      :
      <Signin/>
    }
    </>
  );
}